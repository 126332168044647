
.topic-item {
    .topic-title {
        width: 100%;
        min-height: 50px;
        background-color: #F2F3FA;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 30px;
        &::v-deep .title {
            display: inline-block;
            width: calc(100% - 30px);
            font-size: 14px;
            color: #343441;
            p {
                display: inline-block;
                padding: 0;
                margin: 0;
            }
        }
    }
    .topic-options {
        background-color: #FFF;
        padding: 13px 20px 19px 70px;
        &::v-deep {
            .el-textarea__inner {
                height: 200px;
            }
        }
    }
}
