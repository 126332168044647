
.topic-item {
    .topic-title {
        width: 100%;
        background-color: #F2F3FA;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 30px;
        &::v-deep .title {
            display: inline-block;
            width: calc(100% - 30px);
            font-size: 14px;
            color: #343441;
            p {
                display: inline-block;
            }
        }
    }
    .topic-options {
        background-color: #FFF;
        padding: 13px 0 19px 70px;
        .el-radio-item {
            margin-bottom: 20px;
        }
        .el-checked-item {
            margin-bottom: 20px;
            &::v-deep {
                .el-checkbox__input {
                    &.is-checked {
                        .el-checkbox__inner {
                            background-color: #1122D8;
                            border-color: #1122D8;
                        }
                        &+.el-checkbox__label {
                            color: #1122D8;
                        }
                    }
                }
            }
        }
    }
}
